<template>

  <div class="container-fluid">
    
    <BannerCelltriage title="CellTriage" subtitle=""/>

    <!-- <div class="row">
        <div class="col-12" style="background-color: #f8d7da; color: #721c24; border: 1px solid #f5c6cb; padding: 15px; border-radius: 5px; text-align: center;">
            <h2 style="font-size: 24px; font-weight: bold; margin-bottom: 10px;">Notice: Temporary Update Disruption</h2>
            <p>The delayed MEDLINE baseline release (Jan 10) and a SIBiLS update are causing a temporary interruption in CellTriage updates. The service is expected to resume by mid-February. Thank you for your patience!</p>
        </div>
    </div>  -->


    <div class="row" v-show="user_query.date_min==null">
        <QueryCelltriageBox @showDocuments="showDocuments"/>
    </div>

    <div class="row" v-show="user_query.date_min!=null">
        <ResultsCelltriageBox ref="resultsCellTriageBox"/>
    </div>

    <div class="row" v-show="user_query.date_min!=null && user_query.pmcviewer == null">
        <ExportBox v-if="search_results.medline.length > 0"/>
    </div>

    <div class="row">
      <div class="issue-container">
        <a :href="get_report_link" target="_blank">Bug report</a> | <a :href="get_feature_request_link" target="_blank">Feature request</a>            
      </div>
    </div>

  </div>

</template>

<script>

import BannerCelltriage from '@/components/BannerCelltriage.vue'
import QueryCelltriageBox from '@/components/QueryCelltriageBox.vue'
import ResultsCelltriageBox from '@/components/ResultsCelltriageBox.vue'
import ExportBox from '@/components/ExportBox.vue'
import { mapState, mapActions } from 'vuex'


export default {
  name: 'Home',
  components: {
    BannerCelltriage,
    QueryCelltriageBox,
    ResultsCelltriageBox,
    ExportBox
  },
  data() {
        return {
            showResults: false,
        }
  },
  computed: {
        ...mapState(['user_query', 'search_results']),
        get_report_link(){
          var url = window.location.href
          var params = (new URL(document.location)).searchParams;
          return "https://github.com/sibils/community/issues/new?template=bugreport.yaml&labels=bug,celltriage&service=celltriage&url="+encodeURI(url+params)
        },
        get_feature_request_link(){
          return "https://github.com/sibils/community/issues/new?template=featurerequest.yaml&labels=enhancement,celltriage&service=celltriage"
        },
  },
  methods:{
    ...mapActions(['updateQuerySearch', 'updateQueryDateMin', 'updateQueryDateMax', 'updateDocSource']),
    showDocuments(){
      var options = {}
      if (this.user_query.date_min != null){
        options['from'] = this.user_query.date_min
      }
      if (this.user_query.date_max != null){
        options['to'] = this.user_query.date_max
      }
      this.$router.push({ name: 'CellTriagePage', query: options }).catch(()=>{});
      this.$refs.resultsCellTriageBox.search()
    },
  },
  beforeMount() {
    if ('from' in this.$route.query && this.$route.query.from.length > 0){
      var from = this.$route.query.from
      this.updateQueryDateMin(from)
    }
    if ('to' in this.$route.query && this.$route.query.to.length > 0){
      var to = this.$route.query.to
      this.updateQueryDateMax(to)
    }

  },
  mounted() {
    if (this.user_query.date_min != null && this.user_query.date_max != null){
      this.showDocuments()
    }
    this.updateDocSource("document")
  }
}

</script>

<style scoped lang="scss">

    .container-fluid{
        padding-right: 0px;
        padding-left: 0px;
        margin-right: auto;
        margin-left: auto;
    }

    .row {
        padding: 50px;
        margin-left: 0;
        margin-right: 0;
    }

</style>